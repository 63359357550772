import fb from "../images/facebook.png";

function Footer() {
    return (
      <>        
        <footer class="text-center text-lg-start bg-light text-muted">
            <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                <div class="me-5 d-none d-lg-block">
                    <span>Contáctese con nosotros en las redes.</span>
                </div>
                <div>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-twitter"></i>
                </a>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-google"></i>
                </a>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-instagram"></i>
                </a>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-linkedin"></i>
                </a>
                <a href="" class="me-4 text-reset">
                    <i class="fab fa-github"></i>
                </a>
                </div>
            </section>
            <section class="">
                <div class="container text-center text-md-start mt-5">
                <div class="row mt-3">
                    <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        <i class="fas fa-gem me-3"></i>Veterinaria Ladradoc
                    </h6>
                    <p>
                        Realizamos castraciones, esquilas, vendemos raciones, y más.
                    </p>
                    </div>

                    <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                    <h6 class="text-uppercase fw-bold mb-4">
                        Nuestros servicios
                    </h6>
                    <p>
                        Castración canina
                    </p>
                    <p>
                        Castración felina
                    </p>
                    <p>
                        Perros prenados
                    </p>
                    <p>
                        Gatos prenados
                    </p>
                    </div>
                    
                    <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">Contacto</h6>
                    <p><i class="fas fa-home me-3"></i> URUGUAY (EX RIO NEGRO) M.129 S.7 A UNA CUADRA DE GIANNATTASIO</p>
                    <a href="https://www.facebook.com/profile.php?id=100066804324575&fref=ts"><img src={fb} alt="FB Icon" height={"75px"} /></a>
                    <br></br><br></br>
                    <p><i class="fas fa-phone me-3"></i>Teléfono: 2 681 1262</p>
                    </div>
                </div>
                </div>
            </section>
            <div class="text-center p-4">
                © 2023 Copyright
                <br></br>
                <a class="text-reset fw-bold">Giovanni Canavesi</a>
            </div>
        </footer>
      </>
    );
}
  
export default Footer;
